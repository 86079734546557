import React from "react";
import { Message } from "semantic-ui-react";

const Chat: React.FC = () => {
  return (
    <div style={{ margin: '10px' }}>
      <Message>
        <Message.Header>Under development</Message.Header>
        <p>
          This page is still under development. Please check back later.
        </p>
      </Message>
    </div>
  );
};

export default Chat;
