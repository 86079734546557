import React from "react";
import { Link } from "react-router-dom";
import { Image } from "semantic-ui-react";
import { toggleMode, storeModeInLocalStorage } from "../utils/nightowl";

const NavBar: React.FC = () => {
  return (
    <nav
      style={{
        backgroundColor: "black",
        padding: "10px",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ul
          style={{
            listStyleType: "none",
            margin: 0,
            padding: 0,
            marginLeft: "10px",
          }}
        >
          <li style={{ display: "inline", marginRight: "20px" }}>
            <Link to="/chat" style={{ color: "white", textDecoration: "none" }}>
              Chat
            </Link>
          </li>
          <li style={{ display: "inline", marginRight: "20px" }}>
            <Link
              to="/imagegen"
              style={{ color: "white", textDecoration: "none" }}
            >
              ImageGen
            </Link>
          </li>
        </ul>

        <Image
          src="favicon.png"
          alt="Logo"
          width="20"
          height="20"
          style={{ filter: "invert(1)" }}
          onClick={() => {
            toggleMode();
            storeModeInLocalStorage();
          }}
        />
      </div>
    </nav>
  );
};

export default NavBar;
