import { openDB, IDBPDatabase } from 'idb';

interface ImageDB {
  keyval: {
    key: string;
    value: any;
  };
}

export type GeneratedImage = {
  uuid: string;
  original_prompt: string;
  revised_prompt: string;
  created_at: number; // millisecond timestamp
  url: string;
  content: Blob;
};

const databaseName = 'images';
const storeName = 'imageStore';

class ImageDB {
  private dbPromise: Promise<IDBPDatabase<ImageDB>>;

  constructor() {
    this.dbPromise = openDB<ImageDB>(databaseName, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName);
        }
      },
    });
  }

  async getImages(): Promise<GeneratedImage[]> {
    const db = await this.dbPromise;
    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);
    const images: GeneratedImage[] = await store.getAll();
    await tx.done;
    return images.reverse();
  }

  async writeImages(images: GeneratedImage[]): Promise<void> {
    const db = await this.dbPromise;
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    const allImages = await store.getAll() as GeneratedImage[];
    const allUUIDs = allImages.map((image) => image.uuid);
    await Promise.all(
      allUUIDs.map((uuid) => {
        if (!images.find((i) => i.uuid === uuid)) { // image in db is stale, remove
          return store.delete(uuid);
        }
        return Promise.resolve();
      })
    );
    await Promise.all(
      images.map((image) => {
        // if already exist in db and content size is not 0, skip
        let existing = allImages.find((i) => i.uuid === image.uuid)
        if (existing && !toBeLoaded(existing)) {
          return Promise.resolve();
        }
        return store.put(image, image.uuid);
      })
    );
    await tx.done;
  };
}

const toBeLoaded = (image: GeneratedImage | null | undefined) => {
  if (!image) return false;
  return (!image.content || image.content.size <= 100) && (image.created_at + 1000 * 60 * 60) >= Date.now()
}

const imageDB = new ImageDB();
export { imageDB, toBeLoaded }
