import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import NavBar from "./components/navbar";
import ImageGen from "./pages/image-gen";
import Chat from "./pages/chat";
import Login from "./pages/login";
import "semantic-ui-css/semantic.min.css";

const App: React.FC = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Navigate to="/imagegen" />} />
        <Route path="/login" Component={Login} />
        <Route path="/chat" Component={Chat} />
        <Route path="/imagegen" Component={ImageGen} />
      </Routes>
    </Router>
  );
};

export default App;
