import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { imageDB, toBeLoaded, GeneratedImage } from "../utils/image-db";
import {
  Dropdown,
  Button,
  Loader,
  Dimmer,
  Segment,
  Card,
  TextArea,
} from "semantic-ui-react";
import { uuidv7 } from "uuidv7";

import styles from "./image-gen.module.css";

const ImageGen: React.FC = () => {
  const navigate = useNavigate();

  const [style, setStyle] = useState(styleOpts[0].value);
  const [model, setModel] = useState(modelOpts[0].value);

  const sizeOpts = useMemo(() => {
    return model === "dall-e-2"
      ? [
          { key: "256x256", value: "256x256", text: "256x256" },
          { key: "512x512", value: "512x512", text: "512x512" },
          { key: "1024x1024", value: "1024x1024", text: "1024x1024" },
        ]
      : [
          { key: "1024x1024", value: "1024x1024", text: "1024x1024" },
          { key: "1792x1024", value: "1792x1024", text: "1792x1024" },
          { key: "1024x1792", value: "1024x1792", text: "1024x1792" },
        ];
  }, [model]);
  const [size, setSize] = useState(sizeOpts[0].value);

  useEffect(() => {
    if (model) {
      setSize(sizeOpts[0].value);
    }
  }, [model, sizeOpts]);

  const [quality, setQuality] = useState(qualityOpts[0].value);

  const [prompt, setPrompt] = useState<string>("");

  const [images, setImages] = useState<GeneratedImage[] | null>(null);
  useEffect(() => {
      imageDB.getImages().then((readImages) => {
      setImages(readImages);
    });
  }, []);
  useEffect(() => {
    if (!images) return;
    imageDB.writeImages(images);
  }, [images]);
  useEffect(() => {
    let newImage = images?.find((image) => {
      return toBeLoaded(image);
    });
    if (!newImage || !images) return;
    let promises = images.map((image) => {
      if (!toBeLoaded(image)) return Promise.resolve(image);
      return fetchImageAsBlob(image.url).then((blob) => {
        return { ...image, content: blob };
      });
    });
    Promise.all(promises).then((loadedImages) => {
      setImages(loadedImages);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});
  useEffect(() => {
    if (!images) return;
    setImageUrls((prevUrls) => {
      const newImageUrls = {} as Record<string, string>;
      images.forEach((image) => {
        if (!prevUrls[image.uuid] && !toBeLoaded(image)) {
          newImageUrls[image.uuid] = URL.createObjectURL(image.content);
        }
      });
      return { ...prevUrls, ...newImageUrls };
    });
  }, [images]);

  const [loading, setLoading] = useState<boolean>(false);

  const genImage = async () => {
    if (!prompt || prompt === "") return;
    setLoading(true);
    try {
      const response = await fetch(
        `https://${window.location.hostname}/webapi/imagegen`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          },
          body: JSON.stringify({
            prompt: prompt,
            model: model,
            size: size,
            quality: quality,
            response_format: "url",
          }),
        }
      );

      const body = await response.json();

      const token = response.headers.get("Authorization");
      if (token) {
        localStorage.setItem("token", token);
      }

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/login");
        }
        throw new Error(
          `HTTP error! status: ${response.status}, error: ${body.error}`
        );
      }

      const newImage = {
        uuid: uuidv7() as string,
        original_prompt: prompt as string,
        revised_prompt: body.data[0].revised_prompt as string,
        url: body.data[0].url || ("" as string),
        content: { size: 0 } as Blob,
        created_at: Date.now(),
      } as GeneratedImage;
      setImages([newImage, ...(images || [])].slice(0, 5));
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
      setPrompt("");
    }
  };

  const fetchImageAsBlob = async (imageUrl: string): Promise<Blob> => {
    try {
      if (!imageUrl || imageUrl === "") {
        throw new Error("No image URL provided");
      }
      const response = await fetch(
        `https://${window.location.hostname}/webapi/imagegen/proxy`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          },
          body: JSON.stringify({
            image_url: imageUrl,
          }),
        }
      );
  
      if (response.status === 401) {
        navigate("/login");
      }
  
      const token = response.headers.get("Authorization");
      if (token) {
        localStorage.setItem("token", token);
      }
  
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error fetching and writing image to blob", error);
      throw error;
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          margin: "0.5rem",
          gap: "0.5rem",
        }}
      >
        <TextArea
          value={prompt}
          className={styles.noBlueBorder}
          style={{
            flex: "1 1 auto",
            minWidth: "20rem",
            maxHeight: "4rem",
            resize: "none",
          }}
          onChange={(e, { value }) => setPrompt(value as string)}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
              e.preventDefault();
              genImage();
            }
          }}
        />
        <Button
          className="ui secondary button"
          compact
          onClick={genImage}
          style={{ margin: 0 }}
        >
          Generate
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          margin: "0.5rem",
          gap: "0.5rem",
        }}
      >
        <Dropdown
          compact
          button
          options={styleOpts}
          value={style}
          onChange={(e, { value }) => setStyle(value as string)}
        />
        <Dropdown
          compact
          button
          options={modelOpts}
          value={model}
          onChange={(e, { value }) => setModel(value as string)}
        />
        <Dropdown
          compact
          button
          options={sizeOpts}
          value={size}
          onChange={(e, { value }) => setSize(value as string)}
        />
        <Dropdown
          compact
          button
          options={qualityOpts}
          value={quality}
          onChange={(e, { value }) => setQuality(value as string)}
        />
      </div>

      <Segment
        style={{
          margin: "0.5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "start",
          flexWrap: "wrap",
        }}
      >
        {loading && <Loader active inline="centered" />}

        {images?.map((image) => {
          return (
            <Card
              key={image.uuid}
              style={{ maxWidth: "100%", margin: "0.5rem" }}
            >
              <Dimmer active={toBeLoaded(image)}>
                <Loader />
              </Dimmer>
              {image.content.size > 0 && (
                <img src={imageUrls[image.uuid]} alt="Generated" />
              )}
              {image.original_prompt && (
                <Card.Content
                  onClick={() => {
                    setPrompt(image.original_prompt);
                  }}
                >
                  <Card.Description>{image.original_prompt}</Card.Description>
                </Card.Content>
              )}
              {image.revised_prompt && (
                <Card.Content
                  onClick={() => {
                    setPrompt(image.revised_prompt);
                  }}
                >
                  <Card.Description>{image.revised_prompt}</Card.Description>
                </Card.Content>
              )}
            </Card>
          );
        })}
      </Segment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          className="ui secondary button"
          compact
          onClick={() => {
            setImages([]);
          }}
          style={{ margin: 0, alignSelf: "center" }}
        >
          Clear
        </Button>
      </div>
    </div>
  );
};

const modelOpts = [
  { key: "dall-e-3", value: "dall-e-3", text: "DALL·E 3" },
  { key: "dall-e-2", value: "dall-e-2", text: "DALL·E 2" },
];

const qualityOpts = [
  { key: "standard", value: "standard", text: "standard" },
  { key: "hd", value: "hd", text: "hd" },
];

const styleOpts = [
  { key: "vivid", value: "vivid", text: "vivid" },
  { key: "natural", value: "natural", text: "natural" },
];

export default ImageGen;
